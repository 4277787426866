import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import useInput from "../../hooks/userInput";
import { GoNumber } from "react-icons/go";
import { Helmet } from "react-helmet-async";

import classes from "./Invest.module.css";
import { getWallet } from "../../api/api";
import Spinner from "../UI/Spinner";
import AuthAlert from "../alerts/AuthAlert";
import { createInvestment } from "../../api/api";
import { companyName } from "../../util/config";

const Invest = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [wallet, setWallet] = useState("");
  const { jwt } = useCookies(["jwt"])[0];
  const params = useParams();
  const navigate = useNavigate();

  const {
    value: contractInput,
    enteredValueIsValid: contractInputIsValid,
    hasError: contractInputIsInvalid,
    valueInputChangedHandler: contractInputChangedHandler,
    valueInputBlurHandler: contractInputBlurHandler,
    reset: contractInputReset,
  } = useInput((value) => value.trim() !== "" && value.trim() !== "Choose");

  const {
    value: amountInput,
    enteredValueIsValid: amountInputIsValid,
    hasError: amountInputIsInvalid,
    valueInputChangedHandler: amountInputChangedHandler,
    valueInputBlurHandler: amountInputBlurHandler,
    reset: amountInputReset,
  } = useInput((value) => value.trim() !== "");

  let formIsValid = false;
  if (contractInputIsValid && amountInputIsValid) {
    formIsValid = true;
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    const data = {
      plan: params.id,
      contractLength: contractInput,
      investedAmount: amountInput,
    };

    const res = await createInvestment(data, jwt);

    if (res.status === "success") {
      setAlertMsg(res.message);
      setAlertStatus(true);
      setShowAlert(true);
      setTimeout(() => {
        navigate("/dashboard/investments", { replace: true });
      }, 1500);
    } else {
      setAlertMsg(res.message);
      setAlertStatus(false);
      setShowAlert(true);
    }

    contractInputReset();
    amountInputReset();
    setTimeout(() => {
      setShowAlert(false);
      setShowSpinner(false);
    }, 6000);
  };

  const contractInputClasses = contractInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  const amountInputClasses = amountInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;

  useEffect(() => {
    const fetchWallet = async () => {
      const res = await getWallet(jwt);
      if (res.status === "success") {
        setWallet(res.data.wallets[0].address);
      }
    };
    fetchWallet();
  }, [jwt]);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(wallet);
      alert("Copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  return (
    <div className={classes.container}>
      <Helmet>
        <title>INVEST</title>
        <meta name="description" content={`Invest in ${companyName}`} />
        <link rel="canonical" href="/invest" />
      </Helmet>
      <form className={classes.form} onSubmit={submitHandler}>
        {showAlert && <AuthAlert message={alertMsg} status={alertStatus} />}
        {showSpinner && <Spinner />}
        <div className={classes.instruction}>
          <p>
            Send an amount within the price range of the plan you selected to
            the below USDT BEP20 wallet address and fill in the rest of the
            information needed below.
          </p>
          <div className={classes.walletDetail}>
            <div className={classes.wallet}>
              {wallet}
              <button type="button" onClick={copyToClipboard}>
                copy
              </button>
            </div>
          </div>
        </div>
        <div className={contractInputClasses}>
          <label>Contract Length</label>
          <div className={classes["input-group"]}>
            <GoNumber className={classes.icon} />
            <select
              value={contractInput}
              onChange={contractInputChangedHandler}
              onBlur={contractInputBlurHandler}
            >
              <option>Choose</option>
              <option value={1}>1 Month</option>
              <option value={3}>3 Months</option>
              <option value={6}>6 Months</option>
              <option value={12}>12 Months</option>
            </select>
          </div>
        </div>
        <div className={amountInputClasses}>
          <label>USD amount sent to our wallet</label>
          <div className={classes["input-group"]}>
            <GoNumber className={classes.icon} />
            <input
              type="number"
              value={amountInput}
              onChange={amountInputChangedHandler}
              onBlur={amountInputBlurHandler}
            />
          </div>
        </div>
        <div className={classes.action}>
          <button type="submit" disabled={!formIsValid}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Invest;
